import {graphql} from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PasswordlessForm from "../components/auth0/PasswordLessForm";
import React, { useState, useEffect } from "react"
import CompleteProfileFormB2C from "../components/account/completeProfileFormB2C";
import PasswordLessForm from "../components/auth0/PasswordLessForm";
import AuthenticatorCompleteProfile from "../components/auth0/AuthenticatorCompleteProfile";
import Authenticator from "../components/auth0/Authenticator";
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";

export const query = graphql`
  query CompleteProfileQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ... fragSiteSettings
    }
    post: sanityLandingPage(slug: { current: { eq: "complete-profile" } }) {
      ... fragLandingPage
      content {
        ... fragSanityCourseTitle
        ... fragSanityAvailableCourses
        ... fragSanityTabsTextboxBlock
        ... fragSanityDropdownWidget
        ... fragSanitySocialMediaPosts
        ... fragSanityTwitterBlock
        ... fragSanityLandingSearchBlock
        ... fragSanityBestBuysTable
        ... fragSanityRecProviders
        ... fragSanityTipChooser
        ... fragSanityBreadcrumb
        ... fragSanityPopularConversationsBlock
        ... fragSanityBigBlockGroup
        ... fragSanityDataCaptureForm
        ... fragSanityHeroSection
        ... fragSanityHeaderBlock
        ... fragSanityTitleSection
        ... fragSanityLatestArticlesBlock
        ... fragSanityCalcReference
        ... fragSanityFilteredQuestionsBlock
        ... fragSanityAdvisersList
        ... fragSanityFundList
        ... fragSmallSpacerBlock
        ... fragWidgetSelection
        ... fragSanityEmailCaptureForm
      }
    }
    
  }
`

const CompleteProfile = (props) => {

  const { data, errors } = props;
  const post = data && data.post;
  const getPageUrl = "/"
  const [loading, setLoading] = useState(true);

  //post.dictionary = data && data.dictionary;

  var auth = true;
  if (post.isPrivatePage) {
    auth = Authenticator();
  }

  const [getToken, setToken] = useState(typeof window !== "undefined" ? localStorage.getItem("id_token") : []);
  const [getProfile, setProfile] = useState(typeof window !== "undefined" ? localStorage.getItem("profile") : []);

  const [getProfileSet, setProfileSet] = useState(null);
  const [getPage, setPage] = useState(null)

  useEffect(() => {
    const userProfile = localStorage.getItem('profile');
    const loggedIn = localStorage.getItem('id_token');

    setToken(loggedIn);

    var isProfileSet = userProfile.toLowerCase() == "false" ? "1" : userProfile.toLowerCase()// (String(userProfile).toLowerCase() === 'true')

    localStorage.setItem("profile", isProfileSet)

    //console.log("isProfileSet", isProfileSet)

    setProfileSet(isProfileSet);
    setPage(window.location.pathname);
  }, []);

  if (getProfileSet === null) {
    // You might show a loading state here while checking the authentication
    return null;
  }


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <AuthenticatorCompleteProfile getProfileSet={getProfileSet} fromPage={getPage} getToken={getToken} privatePage={post.isPrivatePage}>
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        canonical={getPageUrl}
      />
      {/*<CompleteProfileFormB2C stage={1}  />*/}
      <PasswordlessForm showCompleteProfileForm={true} />
      </Layout>
    </AuthenticatorCompleteProfile>
  )
}

export default CompleteProfile;
